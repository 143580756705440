.Wrapper {
  display: flex;
  flex-direction: row-reverse;
  min-height: 87vh;
  max-width: 67em;
  margin: auto;
  padding-top: 1em;
}

.Wrapper > * {
  border: 1px solid var(--light);
  border-radius: 0.5em;
  margin: 0.5em;
  height: 100%;
}

.Wrapper > main {
  width: 66%;
  padding: 1em;
}

.Wrapper > aside {
  width: 33%;
  padding: 1em;
  background: var(--black);
  text-align: center;
}

.Wrapper > aside > h1 {
  margin: 0;
  font-size: 1.2em;
  color: var(--orange);
}

.Wrapper > aside a {
  display: block;
  padding: 0.75em 0.5em;
  margin: 0.1em;
  color: var(--light);
  border: 1px solid var(--light);
  border-radius: 0.5em;
  text-align: center;
  text-decoration: none;
  transition: 0.5s;
}

.Wrapper > aside a:hover {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--light);
}

.Wrapper .mobileAside {
  display: none;
}

@media (max-width: 40em) {
  .Wrapper {
    display: block;
    min-height: 85vh;
  }

  .Wrapper img {
    display: block;
    margin: auto;
  }

  .Wrapper > main,
  .Wrapper > aside {
    width: initial;
    transition: 0.5s;
  }

  .Wrapper .mobileAside {
    display: block;
    text-align: center;
    border: none;
    text-align: right;
  }

  .Wrapper:not(.showAside) > aside {
    padding: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}
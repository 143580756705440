html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #111;
  color: #FFF;
  font-family: sans-serif;
}

:root {
  --orange: #E28F1D;
  --blue: #1D70E2;
  --dark: #333333;
  --black: #000000;
  --light: #FAFAFA;
  --white: #FFFFFF;
}
.Footer {
  background: #000;
}

.Footer > div {
  max-width: 67em;
  margin: auto;
  padding: 0.5em;
  display: flex;
}

.Footer > div > * {
  padding: 0 1em;
}
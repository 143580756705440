.Header {
  background: var(--black);
}

.Header > div {
  max-width: 67em;
  margin: auto;
  padding: 0.5em;
  display: flex;
}

.Header> div > * {
  padding: 0 1em;
}

.Header img {
  padding-top: 0.4em;
}

.Header nav {
  padding: 0.6em;
}

.Header nav a {
  color: var(--white);
  text-decoration: none;
  margin-left: 1em;
  transition: 0.5s;
}

.Header .userSection {
  padding: 0.6em;
  margin-left: auto;
}

.Header .userSection a {
  color: var(--white);
  text-decoration: none;
  margin-left: 1em;
}

.Header a:hover {
  text-decoration: underline;
  color: var(--orange);
}

.Header .hamburger {
  display: none;
}

@media (max-width: 50em) {
  .Header > div {
    display: block;
  }

  .Header nav > * {
    display: block;
  }

  .Header div.userSection > * {
    display: block;
  }

  .Header div:not(.showMobileNav) nav,
  .Header div:not(.showMobileNav) div.userSection {
    display: none;
  }

  .Header .hamburger {
    display: block;
    padding: 0.75em;
    transform: rotate(90deg);
    float: right;
  }
}